<template>
    <div class="contact-map">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <iframe class="contact-map-size" src="https://www.google.com/maps/embed/v1/search?q=Yadanarbon+Bank+Ltd.,+Mandalay,+Myanmar+(Burma)&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" allowfullscreen="true" loading="lazy">
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</template>
