<template>
    <div class="wrapper">

      <router-view></router-view>

        <!-- HeaderNavSix section -->
        <HeaderNavFour></HeaderNavFour>

        <div class="main-content">

            <!-- Breadcrumb section -->
            <!-- <Breadcrumb breadcrumbTitle="Contact Us" breadcrumbName="Contact Us"></Breadcrumb> -->

            <!-- Google Maps -->
            <GoogleMaps></GoogleMaps>

            <!-- ContactSection section -->
            <ContactSection></ContactSection>

        </div>

        <!-- Section -->
        <!-- <NewsletterThree newslatterThree="newsletter-area style-4 newsletter-space-y-axis"></NewsletterThree> -->

        <!-- Footer section -->
        <FooterSectionFour class="space-top-140"></FooterSectionFour>

    </div>
</template>

<script>
import HeaderNavFour from '@/components/HeaderNavFour.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import GoogleMaps from '@/components/GoogleMaps.vue'
import ContactSection from '@/components/ContactSection.vue'
import NewsletterThree from '@/components/NewsletterThree.vue'
import FooterSectionFour from '@/components/FooterSectionFour.vue'

export default {
  components: {
    HeaderNavFour,
    Breadcrumb,
    GoogleMaps,
    ContactSection,
    NewsletterThree,
    FooterSectionFour
  }
}
</script>
